<template>
  <section class="about">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="top-banner"
      >
        <v-layout
          align-center
          fill-height
          justify-center
          style="position: relative; text-align: center;"
        >
          <v-flex style="position: absolute; bottom: 40px;" class="ctk-about-submenu">
            <template>
              <v-tabs
                v-model="tabSubMenu"
                light
                grow
              >
                <v-tab
                  v-for="(menu, k) in subMenu"
                  :key="k"
                >
                  {{menu}}
                </v-tab>
              </v-tabs>
            </template>
          </v-flex>
        </v-layout>
    </v-img>
    <v-container  grid-list-md text-center pt-12>
      <v-tabs-items v-model="tabSubMenu">
        <!--联系我们-->
        <v-tab-item key="0">
          <v-container
            fluid
            grid-list-sm
          >
            <v-layout wrap>
              <v-flex class="text-left">
                <span v-html="contact.contact">{{contact.contact}}</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <!--人才招聘-->
        <v-tab-item key="1">
          <v-container
            fluid
            grid-list-sm
          >
            <v-layout wrap>
              <v-flex class="text-left">
                <span v-html="contact.hr">{{contact.hr}}</span>
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <!--在线留言-->
        <v-tab-item key="2">
          <v-container
            fluid
            grid-list-sm
            mb-12
            class="text-left"
          >
            <h3 style="font-size: 32px; color: #333; padding-left:20px; border-left: 4px solid #0080E6; margin-left:20px;">{{this.$t('contact.online.title')}}:</h3>
            <h5 style="font-size: 16px; color: #666; padding-left:50px; font-weight: normal; margin:30px 0px;">{{this.$t('contact.online.subTitle')}}</h5>
            <template>
              <v-form
                v-model="messageForm"
                ref="messageForm"
                style="width:100%"
              >
                <v-container
                  fluid
                  grid-list-sm
                  mb-12
                  class="text-left"
                >
                  <v-layout wrap>
                    <v-flex xs12 sm12 md8 lg8>
                      <v-layout wrap>
                        <v-flex xs12 sm12 md6 lg6 pr-6>
                          <v-text-field
                            outlined
                            name="name"
                            :label="this.$t('contact.online.placeholder.name')"
                            prepend-icon="account_box"
                            :rules="[v => !!v || this.$t('contact.online.valid.name')]"
                            v-model="formData.name"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 pr-6>
                          <v-text-field
                            name="mobile"
                            outlined
                            :label="this.$t('contact.online.placeholder.mobile')"
                            prepend-icon="phone"
                            :rules="[
                              v => !!v || this.$t('contact.online.valid.mobile'),
                              v => /^[1][0-9]{10}$/.test(v) || this.$t('contact.online.valid.mobile')]"
                            v-model="formData.mobile"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 pr-6>
                          <v-text-field
                            name="email"
                            outlined
                            :label="this.$t('contact.online.placeholder.email')"
                            prepend-icon="email"
                            v-model="formData.email"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md6 lg6 pr-6>
                          <v-text-field
                            name="title"
                            outlined
                            :label="this.$t('contact.online.placeholder.title')"
                            prepend-icon="title"
                            v-model="formData.title"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12 md12 lg12 pr-6>
                          <v-textarea
                            name="content"
                            outlined
                            :label="this.$t('contact.online.placeholder.title')"
                            rows="8"
                            prepend-icon="comment"
                            :rules="[v => !!v || this.$t('contact.online.valid.content')]"
                            v-model="formData.content"
                          ></v-textarea>
                        </v-flex>
                        <v-flex xs12 sm12 md12 lg12>
                          <v-btn large color="primary" @click="submitForm" style="margin-left:30px;">
                            {{this.$t('contact.online.submit')}}
                          </v-btn>
                        </v-flex>
                      </v-layout>
                        </v-flex>
                      </v-layout>
                </v-container>
              </v-form>
            </template>
            <template>
              <v-row justify="center">
                <v-dialog v-model="dialog" persistent>
                  <v-card>
                    <v-card-text>{{returnMsg}}</v-card-text>
                  </v-card>
                </v-dialog>
              </v-row>
            </template>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 6,
    tabSubMenu: null,
    subMenu: null,
    messageForm: false,
    returnMsg: null,
    dialog: false,
    formData: {
      name: '',
      mobile: '',
      email: '',
      title: '',
      content: ''
    },
    contact: {
      'contact': null,
      'hr': null
    }
  }),
  created () {
    document.querySelector('#menu_contact').classList.add('v-btn--active')
    if (this.$route.params.pid) {
      this.tabSubMenu = parseInt(this.$route.params.pid)
    }
    this.getPageData()
    this.getContact()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        let locale = localStorage.getItem('locale')
        if (!locale) {
          locale = 'cn'
        }
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      let locale = localStorage.getItem('locale')
      if (!locale) {
        locale = 'cn'
      }
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getPageData () {
      this.subMenu = this.$t('contact.subMenu')
    },
    getContact () {
      this.https.get('contact').then(response => {
        if (response.code === 0) {
          this.contact = response.data
        }
      })
    },
    submitForm () {
      this.$refs.messageForm.validate()
      if (this.messageForm === true) {
        this.https.get('online_message', this.formData).then(response => {
          if (response.code === 0) {
            this.$refs.messageForm.reset()
          }
          this.returnMsg = response.msg
          this.dialog = true
          let _this = this
          setTimeout(function () {
            _this.dialog = false
          }, 2000)
        })
      }
    }
  }
}
</script>
<style>
</style>
